import React, { useEffect, useRef } from 'react';
import Styles from 'components/Payroll/VoucherManagement/BreakDown/BreakDown.module.css';
import castingLogoIcon from 'assets/svg/castingLogo.svg';
import html2canvas from 'html2canvas';
import Jspdf from 'jspdf';
import moment from 'moment';
import { formatCombinedTime, formatTime } from 'helpers/utils';

interface Props {
  setIsBreakdown: Function;
  item: any;
  handleBreakdownPdf?: Function;
}

const BreakDown: React.FC<Props> = (props: Props) => {
  const { setIsBreakdown, item, handleBreakdownPdf } = props;
  const breakdownRef = useRef<any>(null);

  useEffect(() => {
    if (item !== undefined || item !== {}) {
      if (item?.performers !== null) {
        setIsBreakdown(true);
        const element: any = breakdownRef.current;
        html2canvas(element).then((canvas) => {
          const imgWidth = 208;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          const imgData = canvas.toDataURL('img/png');
          const pdf = new Jspdf('p', 'mm', 'a4');
          pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
          const pdfBlob = pdf.output('blob');
          if (handleBreakdownPdf) {
            handleBreakdownPdf(pdfBlob);
          } else {
            pdf.save('breakdown.pdf');
          }
          setIsBreakdown(false);
        });
      }
    }
  }, [item]);

  return (
    <>
      <div
        id="breakdownRef"
        ref={breakdownRef}
        className={Styles.invoiceWrapper}
        style={{ backgroundColor: 'white', marginLeft: '80px', marginTop: '40px' }}
      >
        <div id="wrapper" style={{ marginLeft: '40px', marginRight: '40px', marginTop: '40px', paddingTop: '40px' }}>
          <div className="pb-4">
            <div className="d-flex items-center justify-content-between w-100">
              <div>
                <img src={castingLogoIcon} alt="ubcp" style={{ maxWidth: '150px', width: '100%' }} />
              </div>
              <h4 style={{ fontSize: '24px', marginBottom: '0' }}>Breakdown</h4>
            </div>
          </div>
          <hr />
          <form action="" className="pt-4">
            <div className="d-flex" style={{ maxWidth: '80%', marginInline: 'auto', gap: '16px' }}>
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>Production: </strong>
                <span style={{ color: '#828282', fontSize: '16px' }}>{item?.production || ''}</span>
              </div>
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>Date: </strong>
                <span style={{ color: '#828282', fontSize: '16px' }}>
                  {item?.date !== null ? moment(item?.date).format('MM/DD/YYYY') : ''}
                </span>
              </div>
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>EP: </strong>
                <span style={{ color: '#828282', fontSize: '16px' }} />
              </div>
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>Day: </strong>
                <span style={{ color: '#828282', fontSize: '16px' }}>{item?.day?.replace(/^Day\s*/, '') || ''}</span>
              </div>
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>Of: </strong>
                <span style={{ color: '#828282', fontSize: '16px' }}>{item?.totalShowDays || ''}</span>
              </div>
            </div>
            <table className={`${Styles.tableWrapper} my-4`}>
              <thead>
                <tr>
                  <th className={Styles.tableHeading}>Background Coordinators</th>
                  <th className={Styles.tableHeading}>IN</th>
                  <th className={Styles.tableHeading}>OUT</th>
                  <th className={Styles.tableHeading}>LUNCH</th>
                  <th className={Styles.tableHeading}>NOTES</th>
                </tr>
              </thead>
              <tbody>
                {item?.coordinators?.map((coordinator: any) => (
                  <tr>
                    <td>{coordinator?.name}</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <h4 className="mt-3" style={{ fontSize: '24px', color: '#000000' }}>
              Background Performers
            </h4>
            <table className={`${Styles.tableWrapper} my-4`}>
              <thead>
                <tr>
                  <th className={Styles.tableHeading}>#</th>
                  <th className={Styles.tableHeading}>Category</th>
                  <th className={Styles.tableHeading}>IN</th>
                  <th className={Styles.tableHeading}>OUT</th>
                  <th className={Styles.tableHeading}>LUNCH</th>
                  <th className={Styles.tableHeading}>NDB</th>
                  <th className={Styles.tableHeading}>RENTAL</th>
                  <th className={Styles.tableHeading}>MP</th>
                  <th className={Styles.tableHeading}>NOTES</th>
                </tr>
              </thead>
              <tbody>
                {item?.performers?.map((p: any, index: number) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{p?.rate || p?.voucherType || p?.jobId?.rate?.title}</td>
                    <td>{formatTime(p.signInTime)}</td>
                    <td>{formatTime(p.wrapTime)}</td>
                    <td>{formatCombinedTime(p.lunch)}</td>
                    <td>{p.NDB && 'Y'}</td>
                    <td>{p?.rental}</td>
                    <td>{p?.totalMealPenalty > 0 ? 'Y' : 'N'}</td>
                    <td>{p.note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div
              className="d-flex mt-3 flex-wrap justify-content-between"
              style={{ maxWidth: '100%', marginInline: 'auto', gap: '16px' }}
            >
              {item?.allRatesData?.map((rateListData: any) => (
                <div>
                  <strong style={{ color: '#4B4D67', fontSize: '16px' }}>{rateListData?.title}</strong>
                  <span className="mx-2">:</span>
                  <span className="mb-0" style={{ color: '#828282', fontSize: '16px' }}>
                    {rateListData?.totalRecords}
                  </span>
                </div>
              ))}
              <div>
                <strong style={{ color: '#4B4D67', fontSize: '16px' }}>Total</strong>
                <span className="mx-2">:</span>
                <span className="mb-0" style={{ color: '#828282', fontSize: '16px' }}>
                  {item?.performers?.length}
                </span>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default BreakDown;
